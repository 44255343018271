<template>
    <div class="AgentCashIn">
        <van-row type="flex" justify="center" class="topmenu">
            <van-col span="6">
                <div class="tabone" :class="{ 'active': active == 1 }" @click="changeActive(1,0)">全部收益</div>
            </van-col>
            <van-col span="6">
                <div class="tabone" :class="{ 'active': active == 2 }" @click="changeActive(2,1)">待到账</div>
            </van-col>
            <van-col span="6">
                <div class="tabone" :class="{ 'active': active == 3 }" @click="changeActive(3,2)">已到账</div>
            </van-col>
        </van-row>

        <van-empty description="暂无收益，努力哦～～" v-if="cashlist.length <= 0">
            <van-button round type="danger" class="bottom-button" to="/agent">
                开始赚钱
            </van-button>
        </van-empty>

        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" v-else>
             <van-cell-group>
                <van-cell v-for="(item,index) in cashlist" :key="index" :title="`¥` + item.money" :label="`在****的推广中获得收益¥` + item.money">
                    <template #default>
                        <van-tag plain type="success" v-if="item.status === 1">待到账</van-tag>
                        <van-tag plain type="danger" v-else-if="item.status === 2">已到账</van-tag>
                        <van-tag plain type="primary" v-else>无效</van-tag>
                    </template>
                </van-cell>
             </van-cell-group>
        </van-list>
    </div> 
</template>

<script>
export default {
    name: "AgentCashIn",
    data() {
        return {
            active: this.$route.query.active || 1,
            cashlist: [],
            loading:false,
            finished:false,
            page:1,
            over: false,
            status: this.$route.query.active -1 || 0,
        }
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            if(!this.over){
                axios.get(
                    "income",{
                        params:{
                            status:this.status ,
                            page:this.page
                        }
                    }
                ).then(result => {
                    if(result.code == 1){
                        if(result.data.data.length == 0){
                            this.finished = true
                            this.over = true
                            return 
                        }
                        this.loading = false
                        this.page++;
                        this.cashlist.push(...result.data.data)
                    } else{
                        this.$toast(result.message);
                    }
                })
            }
        },
        changeActive(active,status) {
            this.active = active;
            this.status = status
            //初始化
            this.cashlist = []
            this.finished = false
            this.over = false
            this.page = 1
            this.getList()
        },
        onLoad() {
            this.getList();
        },
    }   
};
</script>

<style scoped>
.topmenu {
    border-bottom: 2px solid #f2826a;
    font-size: 12px;
}
.tabone {
    font-size: 14px;
    text-align: center;
    color: #000;
    height: 40px;
    line-height: 50px;
    display: block;
    border-radius: 5px 5px 0px 0px;
}
.tabone.active {
    background-color: #f2826a;
    color: #fff;
    font-weight: 700;
}
.bottom-button {
    width: 160px;
    height: 40px;
}
</style>
